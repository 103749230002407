<template>
  <div class="menu">
    <a-menu
      :selectedKeys="selectedKeys"
      :openKeys="openKeys"
      theme="dark"
      mode="inline"
    >
      <a-menu-item key="1">
        <HomeOutlined />
        <span class="nav-text"><router-link to="/">首页</router-link></span>
      </a-menu-item>
      <a-menu-item key="2">
        <BookOutlined />
        <span class="nav-text"><router-link to="/foods">食物列表</router-link></span>
      </a-menu-item>
      <a-menu-item key="3">
        <BarsOutlined />
        <span class="nav-text"><router-link to="/recipe">配方列表</router-link></span>
      </a-menu-item>
      <a-menu-item key="4">
        <UserOutlined />
        <span class="nav-text"><router-link to="/pet-users">用户列表</router-link></span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
// import { ref } from "vue";
export default {
  name: "SiderMenu",
  props: ['selectedKeys', 'openKeys'],
  setup() {
    return {
    };
  },
};
</script>

<style lang="scss">
.ant-menu-item {
  a {
    color: #fff !important;
  }
}
</style>
