import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/Index.vue'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {
      menuKey: [['1'], ['']],
      breadcrumb: ['超级车间']
    },
    children: [
      {
        path: '/recipe',
        name: 'Recipes',
        component: () => import('../views/Recipes.vue'),
        meta: {
          menuKey: [['3'], ['']],
          breadcrumb: ['wallewalle', 'recipes']
        }
      }, {
        path: '/foods',
        name: 'Foods',
        component: () => import('../views/Foods.vue'),
        meta: {
          menuKey: [['2'], ['']],
          breadcrumb: ['wallewalle', 'foods']
        }
      }, {
        path: '/pet-users',
        name: 'PetUsers',
        component: () => import('../views/PetUsers.vue'),
        meta: {
          menuKey: [['4'], ['']],
          breadcrumb: ['wallewalle', 'pet-users']
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
